import SwalDefault from './swal-default';

/**
 * JS para auxiliar nos formulários
 */
window.base_url = window.base_url || '';

const $window = $(window);
const $body = $(document.body);
const $applicationRootElement = $body.find('.application');

window.addEventListener('load', function() {

    /**
     * Setup do token de validação no cabeçalho
     */
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    /**
     * Máscaras disponíveis para uso
     */
    $(".date-field-mask").mask('99/99/9999', { clearIfNotMatch: true, placeholder: 'dd/mm/aaaa' });

    /**
     * Editores de texto
     *   tinyMCE
     */
    $('.text-editor-fields').each(function(index, element) {
        const $element = $(element);
        const classIdentifier = `text-editor-${index}`;

        $element.addClass(classIdentifier);

        /**
         * Referência
         * @url https://stackoverflow.com/questions/21792540/add-class-to-element-inserted-with-tinymce
         */
        const editor = tinymce.init({
            selector: `.${classIdentifier}`,
            language_url: `${BASE_URL}/assets/admin/js/pt_BR.js`,
            language: 'pt_BR',
            toolbar: "insertfile undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image codesample code",
            //plugins: "paste,filemanager,imagemanager,advimage,inlinepopups",
            content_css : `${BASE_URL}/assets/front/css/pages/blog-post-stylesheet.css`,
            extended_valid_elements: "p[class=paragraph],h2[class=h2],h3[class=h3],h4[class=h4],h5[class=h5],h6[class=h6]",
            document_base_url: BASE_URL,
            style_formats: [
                {title: 'Headers', items: [
                    {title: 'Título 2', block: 'h2', classes: 'h2' },
                    {title: 'Título 3', block: 'h3', classes: 'h3' },
                    {title: 'Título 4', block: 'h4', classes: 'h4' },
                    {title: 'Título 5', block: 'h5', classes: 'h5' },
                    {title: 'Título 6', block: 'h6', classes: 'h6' },
                ]},
    
                {title: 'Em linha', items: [
                    {title: 'Negrito', inline: 'b', icon: 'bold'},
                    {title: 'Itálico', inline: 'i', icon: 'italic'},
                    {title: 'Sublinahdo', inline: 'span', styles : {textDecoration : 'underline'}, icon: 'underline'},
                    {title: 'Tachado', inline: 'span', styles : {textDecoration : 'line-through'}, icon: 'strikethrough'},
                    {title: 'Sobrescrito', inline: 'sup', icon: 'superscript'},
                    {title: 'Subscrito', inline: 'sub', icon: 'subscript'},
                    {title: 'Código', inline: 'code', icon: 'code'},
                ]},
    
                {title: 'Blocos', items: [
                    {title: 'Parágrafo', block: 'p', classes: 'paragraph'},
                    {title: 'Aspas', block: 'blockquote'},
                    {title: 'Div', block: 'div'},
                    {title: 'Pre', block: 'pre'}
                ]},
    
                {title: 'Alinhamento', items: [
                    {title: 'Esquerda', block: 'div', styles : {textAlign : 'left'}, icon: 'alignleft'},
                    {title: 'Centro', block: 'div', styles : {textAlign : 'center'}, icon: 'aligncenter'},
                    {title: 'Direita', block: 'div', styles : {textAlign : 'right'}, icon: 'alignright'},
                    {title: 'Justificado', block: 'div', styles : {textAlign : 'justify'}, icon: 'alignjustify'}
                ]}
            ],
            /**
             * Remove o aviso de "domínio não registrado"
             * @url https://stackoverflow.com/questions/50038470/tinymce-shows-error-in-the-editor
             */
            init_instance_callback : function(editor) {
                $('.tox-notifications-container').find('.tox-notification__dismiss').trigger('click');
            }
        });
    
        editor.then(function(e){
            for (let i = 0; i < e.length; i++) {
                if( e[i].id == "id-input-descricao_completa"){
                    if( e[i].getContent() == 0 ){
                        tinymce.activeEditor.setContent('<h3>Descrição do proprietário</h3><p>Texto aqui</p> <h3>Imóvel</h3><p>Texto aqui</p> <h3>Condomínio</h3><p>Texto aqui</p> <h3>O que preciso para alugar esse imóvel?</h3><p>Texto aqui</p>');
                    }
                }
            }
        });
    });


    /**
     * Iniciliza os pares de campos de edição
     */

    const $slugifyFields = $('.slugify-field');

    $slugifyFields.each(function(_, element) {
        
        const $target = $(element);
        const selector = $target.data('slugify-field-selector');
        /**
         * Campo que exibe o texto dos campo com o texto completo
         */
        const $slugifyField = $(selector);

        // Atualiza o input que exibe o valor final do campo slugify
        $target.on('keyup blur input', function(_) {
            $slugifyField.val(slugify($target.val()))
        })
    });
    
});


//expondo projeto padrao
//window.APP = APP;

/**
 * Páginas
 */
window.customDropzone = require('./customDropzone');


$('.bread-action').click(function(event) {
    var $target = $(event.currentTarget);

    var method = $target.data('method') || 'get';
    var link = $target.data('link');

    if (method == 'get') {
        window.location = link;
    } else {

        SwalDefault
            .promptConfirmation({
                title: 'Confirmar exclusão?'
            })
            .then((result) => {
                if (result.value) {
                    fetch(link ,{
                        method: method,
                        credentials: "same-origin",
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({
                            "id": $target.data('entry-id'),
                            '_token': $('meta[name="csrf-token"]').attr('content')
                        })
                    }).then(function(response){
                        window.location.reload();
                    }).catch(function(error){
                        console.log(error);
                        alert('Erro ao realizar a ação');
                    });
                }
            })
    }

})

if ($(window).innerWidth() < 1200) {
    $applicationRootElement.removeClass('side-bar-expanded');
}