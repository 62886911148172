var customDropzone = {
    /**
     * 
     * @param {String} dropzoneName Nome do dropzone.
     * @param {String} dropzoneType Tipo do dropzone ex: Image, file... 
     * @param {String} dropzoneFileType Tipos de arquivos suportados.
     * @param {Int} dropzoneMaxFile Quantidade de arquivos de envio.
     */
    start: function(dropzoneName, dropzoneType, dropzoneFileType, dropzoneMaxFile){

        Dropzone.autoDiscover = false;

        $("#dropzone-"+dropzoneName).each(function() {
            $(this).addClass('dropzone');
            var elementId = dropzoneName;
            var uploadType = dropzoneType;
            var dataFieldName = dropzoneName;
            var acceptedFiles = dropzoneFileType;
            var maxFiles = dropzoneMaxFile;
    
            //create input value image
            // var inputImage = '<input name="'+dataFieldName+'[]" type="hidden" value="empty">';
            $(`.multiple-images-block-${dropzoneName}`).append(inputImage);
    
            //create inputs for images loaded
            if(images_loaded != undefined && images_loaded.length > 0){
                for (var i = 0; i < images_loaded.length; i++) {
                    var linkImage = images_loaded[i].split('|');
                    var msg = linkImage[1].replace(/\//g, '');
                    var nameLink = msg.split('.')[1] + '-' + msg.split('.')[0];
                    var inputImage = '<input name="'+dataFieldName+'[]" class="input-'+elementId+'" type="hidden" id="' + nameLink + '" value="' + images_loaded[i] + '">';
                    $(`.multiple-images-block-${dropzoneName}`).append(inputImage);
                }
            }
    
            if(uploadType == 'file'){
    
                var previewTemplate =   '<div class="fotosp dz-preview" data-url="imagem">'
                    +'<div class="dz-image">'
                        +'<img />'
                    +'</div>'
                    +'<div class="dz-details">'
                    +'</div>'
                    +'<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>'
                    +'<div class="dz-success-mark"><span>✔</span></div>'
                    +'<div class="dz-error-mark"><span>✘</span></div>'
                    +'<div class="dz-error-message"><span data-dz-errormessage></span></div>'
                +'</div>';
    
            }else{
                var previewTemplate =   '<div class="dz-preview dz-file-preview" data-url="imagem">'
                    +'<div class="dz-image">'
                        +'<img data-dz-thumbnail/>'
                    +'</div>'
                    +'<div class="dz-details">'
                    +'</div>'
                    +'<div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>'
                    +'<div class="dz-success-mark"><span>✔</span></div>'
                    +'<div class="dz-error-mark"><span>✘</span></div>'
                    +'<div class="dz-error-message"><span data-dz-errormessage></span></div>'
                +'</div>';
            }
    
            var myDropzone = new Dropzone(this, {
                addRemoveLinks: false,
                paramName: dataFieldName,
                previewTemplate: previewTemplate,
                parallelUploads: 10,
                acceptedFiles: acceptedFiles,
                uploadMultiple: false,
                maxFiles: maxFiles,
                init: function () {
                    this.on('success', function (file, responseText) {
                        var msg = responseText;
                        // console.log(msg);
                        // var res = msg.split(';');
                        // var imagePath = res[1] + '/'+ res[2] + '.' + res[3];
                        // var nameLink = res[3] + '-' +res[2] ;
                        var nameLink = `${dropzoneName}-${msg.nome}`;

                        if(uploadType == 'file'){
                            
                            //create original filename
                            var $filename = $('<p class="filename"> '+ msg.original + '</p>');
                            $(file.previewElement).append($filename);
                            
                            //create input value image
                            var a = $('<input name="'+dataFieldName+'[]" type="hidden" id="' + nameLink + '" data-identifier="'+nameLink+'" value="">');
                            var inputImage = a.val(JSON.stringify(msg));
                            // var inputImage = '<input name="'+dataFieldName+'[]" type="hidden" id="' + nameLink + '" value="'+JSON.stringify(msg)+'">';
    
                            $(file.previewElement).find('img').attr('src', window.root_path+'assets/admin/img/icons/'+getIconByFilename(msg.extension));
                        } else{
                            //create poster link
                            var $posterBtn = $('<a class="poster-image" data-file-name="' + nameLink + '"><div class="poster-dropzone"></div></a>');
                            $(file.previewElement).append($posterBtn);
                            
                            //create input value image
                            // var inputImage = '<input name="'+dataFieldName+'[]" class="input-'+elementId+'" type="hidden" id="' + nameLink + '" value="0|' + msg.extension + '">';
                            var a = $('<input name="'+dataFieldName+'[]" type="hidden" id="' + nameLink + '" data-identifier="'+nameLink+'" value="">');
                            var inputImage = a.val(JSON.stringify(msg));
                        }
    
                        //create delete link
                        var $deleteBtn = $('<a class="delete-image" title="Remover imagem" data-file-name="' + nameLink + '"><div class="delete-dropzone"></div></a>');
                        $(file.previewElement).append($deleteBtn);
    
                        $(`.multiple-images-block-${dropzoneName}`).append(inputImage);
                    });
                },
            });
    
            $('.dropzone').delegate('.delete-image', 'click', function(){
                name = $(this).attr('data-file-name');
                console.log(name);
                $('#'+name).remove();
                $(`[data-identifier="${name}"]`).remove();
                $(this).parent('.dz-preview').remove();
            });
    
            $('.dropzone').delegate('.poster-image', 'click', function(){
                name = $(this).attr('data-file-name');
    
                //remove poster all itens
                var itens = $(`.multiple-images-block-${dropzoneName}`).find('.input-'+elementId);
                itens.each(function(index) {
                    $(this).val($(this).val().replace('1|', '0|'));
                });
                $(this).parent().parent().find('.dz-preview').removeClass('poster');
    
                //add poster to item            
                $('#'+name).val($('#'+name).val().replace('0|', '1|'));
                $(this).parent('.dz-preview').addClass('poster');
            });
    
        });
    }
}

window.getIconByFilename = function getIconByFilename(filename)
{
    filename = filename.toLowerCase();
    if(filename.indexOf('.pdf') != -1){
        return 'pdf.svg';
    } else if(filename.indexOf('.doc') != -1 || filename.indexOf('.docx') != -1){
        return 'doc.svg';
    } else if(filename.indexOf('.ppt') != -1 || filename.indexOf('.pptx') != -1){
        return 'ppt.svg';
    } else if(filename.indexOf('.txt') != -1){
        return 'txt.svg';
    } else{
        return 'file.svg';
    }
};

module.exports = customDropzone;